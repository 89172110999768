// Do not touch: used by webpack to bundle CSS
import styles from './../css/main.css';

// Libraries
import './utils/modernizr-custom-3.5.0'
import $ from 'jquery'
//import Headroom from 'headroom.js'

// Only if one needs to support particular touch interactions
//import TouchEmulator from 'hammer-touchemulator'
//import Hammer from 'hammerjs'

// Detects
import MQ from './utils/mq'

// Polyfills
// Note: arrow functions, const, let, template literals, classes and special characters
// are transformed by babel at compile time
import 'core-js/es6/promise' // instead of import 'babel-polyfill' -> https://stackoverflow.com/a/36350399
import 'core-js/es6/array' // for Array.from
import objectFitImages from 'object-fit-images'

// Other utils
import GDPRElement from './modules/gdpr/gdpr'
import './modules/gdpr/ga'

GDPRElement('.gdpr-ga-state');
GDPRElement('.gdpr-ga-alert');

/*
=============
*/
// Wp Modules
import '../../wp-content/themes/project/modules/gallery-sliding-panel/controller.js';
import '../../wp-content/themes/project/modules/hero-home/controller.js';
import '../../wp-content/themes/project/modules/hero-project/controller.js';
import '../../wp-content/themes/project/modules/image-col-1/controller.js';
import '../../wp-content/themes/project/modules/image-col-2/controller.js';
import '../../wp-content/themes/project/modules/image-col-3/controller.js';
import '../../wp-content/themes/project/modules/image-single-full/controller.js';
import '../../wp-content/themes/project/modules/image-slider/controller.js';
import '../../wp-content/themes/project/modules/menu-header/controller.js';
import '../../wp-content/themes/project/modules/paragraph/controller.js';
import '../../wp-content/themes/project/modules/same-category/controller.js';
import '../../wp-content/themes/project/modules/selected-press/controller.js';
import '../../wp-content/themes/project/modules/sidebar-filters/controller.js';
import '../../wp-content/themes/project/modules/social-feed/controller.js';
import '../../wp-content/themes/project/modules/team-section/controller.js';
import '../../wp-content/themes/project/modules/video/controller.js';

// Views
import { wall } from './views/wall'

if(document.querySelectorAll('main.wall').length > 0){
	wall();
}

// ====================================
// Images lazy
// ====================================
document.addEventListener("DOMContentLoaded", function() {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    console.log('Intersection observer available')
    lazyloadImages = document.querySelectorAll('*[data-visible="false"]');

    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
					var imageChild = image.firstElementChild;

					image.dataset.visible = 'true';
					image.classList.add('prj-entrance');

					imageChild.src = imageChild.dataset.src;
					imageChild.classList.add('PROVA');

          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function(image) {
      imageObserver.observe(image);
    });

    } else {
      console.log('No intersection observer available');
      var lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll(".lazy");

      function lazyload () {
        if(lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function() {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function(img) {
              if(img.offsetTop < (window.innerHeight + scrollTop)) {
                img.src = img.dataset.src;
                img.classList.remove('lazy');
                /* Load parent */
                var parentFig = img.parentElement;
                parentFig.classList.remove("lazyParent");
                parentFig.classList.remove("lazyAnimation");
                parentFig.classList.remove("safe-height");
              }
          });
          if(lazyloadImages.length == 0) {
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
        }, 20);
      }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
})


// ====================================
// Ajax load more
// ====================================
// $('.show-more button').on('click', function(){
//
// 	let hiddenPosts = document.querySelectorAll('*[data-visible="false"]');
//
// 	for(var i = 0; i<6; i++) {
// 		if(hiddenPosts[i]){
// 			hiddenPosts[i].dataset.visible = 'true';
// 			hiddenPosts[i].classList.add('prj-entrance');
// 		} else {
// 			$(this).prop('disabled', true);
// 		}
// 	}
// })

// ====================================
// Display gallery only when loaded
// ====================================
$(window).on('load', function(){
  var target = $('.prj-single img');
	var bg = $('.prj-single');
  // target.css('filter', 'blur(0)');
	target.css('opacity', '1');
	bg.css('background-color', 'transparent');
});


// ====================================
// Slick
// ====================================
import './modules/slick-slider/slick'
$(".slider-center").slick({
	dots: true,
	infinite: true,
	centerMode: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
});

// ====================================
// Breakpoints
// ====================================
const mq = MQ([320, 480, 586, 640, 768, 840, 960, 1024, 1280, 1400]); // available breakpoints

checkIfOver320();
$(window).on('resize', checkIfOver320);

function checkIfOver320() {
  if(mq.over320) { // Configure viewports
    console.log('viewport over 320px');
  }
}

objectFitImages();

// ====================================
// Hide header
// ====================================
$(window).on('load resize', function(){

	if(document.location.href.indexOf('wall') === -1) {

		if ($(window).width() > 768) {
		   console.log('More than 768!');
			 var prevScrollpos = window.pageYOffset;
			 window.onscroll = function() {
			 var currentScrollPos = window.pageYOffset;
			   if (prevScrollpos > currentScrollPos || currentScrollPos <= 0) {
			     document.getElementById("header").style.top = "0";
			   } else {
			     document.getElementById("header").style.top = "-3rem";
			   }
			   prevScrollpos = currentScrollPos;
			 }
		}
		else {
		   console.log('Less than 768');
			 document.getElementById("header").style.top = "0";
		}

	}

});


// ====================================
// Menu active page
// ====================================
$(document).ready(function ($) {
  var url = window.location.href;
  var activePage = url;
  $('header.menu-wrapper li a').each(function () {
    var linkPage = this.href;

    if (activePage == linkPage) {
      $(this).closest("li").addClass("current");
    }
  });
});

// ====================================
// Logo home hover
// ====================================
var hoverLogo = false;

$('img.logo-img').on('mouseover', function() {
	$(this).attr('src', 'https://mammafotogramma.com/wp-content/uploads/2019/04/logo-hover.gif');

	var hoverLogo = true;
})

// ====================================
// Showreel
// ====================================
showreelPanelSlider();

function showreelPanelSlider() {
	var btn = $('.showreel');
	var panel = $('.showreel-panel');
	var panelActive = $('.active-panel');
	var btnClose = $('.showreel-panel-btn-close');
	var body = $("body:not(.showreel-panel-btn-close)");

	btn.on('click', function() {
		panel.toggleClass('toggleShowreelPanel');
		body.addClass('freeze');
	});

	panelActive.add(btnClose).on('click', function() {
		panel.toggleClass('toggleShowreelPanel');
		body.removeClass('freeze');
	});

}


// ====================================
// Single project header color
// ====================================
changeColorHeader();

function changeColorHeader() {
	var obj = $('div.hero-single-prj');
	var target = $('header.menu-wrapper');

	var color = obj.css('background-color');
	console.log(color);
	target.css('background-color', color);
}

// ====================================
// Single project card color
// ====================================
// changeColorCard();
// $(window).on('resize', changeColorCard);
//
//
// function changeColorCard() {
// 	if( $(window).width() < 420 ) {
// 		var target = $('div.prj-card');
// 		target.css('background-color', 'white');
// 	}
// }

// ====================================
// Iframe append wrapper
// ====================================
iframeWrapper();

function iframeWrapper() {
	var iframe = $('.project iframe');
	// :not(.first-bar)
	iframe.wrap( "<div class='embed-container'></div>" );
}

// ====================================
// Subinfo append wrapper
// ====================================
subinfoWrapper();

function subinfoWrapper() {
	var iframe = $('.prj-subinfo-wrapper p');
	iframe.wrap( "<div class='subinfo-single-wrapper'></div>" );
}
