import $ from "jquery";
import ScrollMagic from "scrollmagic";
var gsap = require("gsap");
import 'debug.addIndicators';
import 'animation.gsap';

class WallScroll {
  constructor(element, options) {
    this.element = element;
    this.selector = options.selector;

    this.children = element.children;

    this.wrapStyles = '';

    this._createSlides();

    this.slides = this.element.children;

    this.element.classList.add('loaded');
  }

  _createSlides() {
    [...this.children].forEach( (child, index) => {
      let wrapper = document.createElement('div');
      let imageWrapper = document.createElement('div');
      wrapper.classList.add('wallscroll__slide');
      imageWrapper.classList.add('image__wrapper');
      wrapper.dataset.index = index;
      let paragraph = document.createElement('p');
      let caption = child.dataset.caption;
      paragraph.innerText = caption;

      imageWrapper.appendChild(child);
      wrapper.appendChild(imageWrapper);
      wrapper.appendChild(paragraph);
      this.element.appendChild(wrapper)
    })
  }

  static init(options = { selector: '.wall-scroll' }) {
    window.WALLSCROLL = [];
    const walls = Array.from(document.querySelectorAll(`${options.selector}`));
    let previousWidth = window.innerWidth;
    let timer;

    for (let i = 0; i < walls.length; i++) {
      const wall = walls[i];
      const instance = new WallScroll(wall, options);
      WALLSCROLL.push(instance);
    }
  }
}

export function wall() {

  WallScroll.init({
    selector: '.wall-scroll',
  });

  var controller = new ScrollMagic.Controller();
  var slides = document.querySelectorAll('.wallscroll__slide');

  [...slides].forEach( (slide, index) => {
    // build scene
    var scene = new ScrollMagic.Scene({
      triggerElement: slide,
      triggerHook: 'onCenter',
      duration: $('.wallscroll__slide').outerHeight()
    })
    .addTo(controller)
    // .addIndicators()
    .on("enter leave", function(e){
      if(e.type == "enter"){
        $("[data-index='"+index+"']").removeClass("next").removeClass("prev").addClass("active");
        $("[data-index='"+index+"'] .image__wrapper img").addClass("movement");
        $("[data-index='"+index+"'] p").addClass("capt-movement");
      } else {
        $("[data-index='"+index+"']").removeClass("active");
        $("[data-index='"+index+"'] .image__wrapper img").removeClass("movement");
        $("[data-index='"+index+"'] p").removeClass("capt-movement");
      }
    })
    .on("start end", function(e){
      if(e.type == "start"){
        $("[data-index='"+index+"']").removeClass("prev").addClass("next");
      } else {
        $("[data-index='"+index+"']").removeClass("next").addClass("prev");
      }
    })

    })

}
