import $ from 'jquery'

  // ====================================
  // Sidebar
  // ====================================
  filtersState();
  panelSliderEn();
  panelSliderIt();

  function filtersState() {
    var btn = $('span.filter-head');
    var triangle = $('p.triangle')
    var filters = $('span.filters-list');
    btn.on('click', function() {
      if ($(this).find(triangle).text() == '▲') {

        var el = $(this).next(filters);
        var elHeight = el.height();
        var speedRatio = 2.5;
        var duration = speedRatio * elHeight;

        var speed = Math.abs(duration);

      	if (speed > 680) {
      		speed = 680;
      	} else if (speed < 180) {
      		speed = 180;
      	}

        $(this).find(triangle).text('▼');
        el.slideToggle(speed,'linear');
      }
      else {
        var el = $(this).next(filters);
        var elHeight = el.height();
        var speedRatio = 2.5;
        var duration = speedRatio * elHeight;

        var speed = Math.abs(duration);

      	if (speed > 680) {
      		speed = 680;
      	} else if (speed < 180) {
      		speed = 180;
      	}

        $(this).find(triangle).text('▲');
        el.slideToggle(speed,'linear');
      }
    })
  }

  function panelSliderEn() {
    $('.filter-btn-en').click(function sidebarOpenerCloser() {
      if ($(this).text() == 'Filters') {
        $(this).text('✕');
      } else {
        $(this).text('Filters');
      }
      $('.sidebar-filters').toggleClass('toggleSidebar');
    });
  }

  function panelSliderIt() {
    $('.filter-btn-it').click(function sidebarOpenerCloser() {
      if ($(this).text() == 'Filtri') {
        $(this).text('✕');
      } else {
        $(this).text('Filtri');
      }
      $('.sidebar-filters').toggleClass('toggleSidebar');
    });
  }
